  import React from 'react'
  import Layout from '../../../../../components/App/Layout'
  import Navbar from '../../../../../components/App/NavbarRV'
  import Footer from '../../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../../assets/images/pages/1-5-2-1.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expertise-batiment/'>Expertise technique batiment</a></li><li><a href='/expertise-construction/expertise-batiment/garanties-construction/'>Garanties de construction</a></li><li>GPA</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>La garantie de parfait achèvement (GPA) </h1>

<p>Tous désordres constatés par le Maître d’ouvrage, à réception de l’ouvrage sont couverts par la garantie de parfait achèvement pendant un délai de un an.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="GPA" />
</div>

<p> </p>
<p>Cette garantie permet à l’acquéreur de se prémunir d’éventuels désordres qui pourraient se révéler pendant un an (quatres saisons) en ce, en plus des réserves émises lors de la réception.</p>
<h2>Désordres concernés par la garantie de parfait achèvement (GPA)</h2>

<p>La garantie de parfait achèvement diffère des autres garanties de par la nature des désordres concernés. </p>
<p>En effet, cette souscription acquise au propriétaire, concerne les défauts de construction pouvant engager la solidité structurelle de l’ouvrage et le rendant impropre à sa destination. </p>

<p>Les désordres concernés par la garantie de parfait achèvement sont :</p>

<ul><li>les désordres qui apparaissent durant l’année qui suit la réception du chantier (fissures sur le gros œuvre, dilatation, désordres esthétiques liés aux différentiels thermiques. </li>
<li>désordres mentionnés aux réserves et non réparés par le constructeur.</li></ul>

<p>Les délais de réparation sont généralement fixés d’un commun accord entre l’entreprise et vous-même.</p>

<p>En cas de non intervention dans les délais fixés, vous pouvez saisir le tribunal compétent (voir assistance expertise)</p>
<h2>Le cabinet RV EXPERTISES vous accompagne </h2>

<p>Le cabinet RV EXPERTISES vous accompagne dans le cadre d’absence de prise en charge par le constructeur de désordres constatés et concernés par la garantie de parfait achèvement. Dans un premier temps, nous recherchons des solutions amiables entre vous et le constructeur, afin que ce dernier reprenne les désordres concernés.</p>

<p>En revanche, si refus du constructeur, ou absence de réponse, un rapport technique vous permettra d’apporter les pièces argumentaires nécessaires afin de défendre vos intérêts devant le tribunal compétent.   </p>


<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Des questions ? Envoyez-nous un message</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      

                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details